import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import thumbIcon from 'images/parent_thumb.svg';
import { AnimatePresence, motion } from 'motion/react';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './faqs.module.css';
import { FAQs } from './parentFAQs';

interface ITipProps {
  tip: { header: string; content: string };
  number: number;
}

interface IQuestionProps {
  focusedFaq?: string;
  question?: string;
  body?: { id?: string; answer?: string; heading?: string; video?: string }[];
  id?: string;
}

interface ISectionProps {
  focusedFaq: string;
  questions: IQuestionProps[];
  title: string;
}

const FAQInfoTip: FC<ITipProps> = ({ tip, number }) => (
  <div className={styles.TipBlock}>
    <div className={styles.TipNumber}>{number + 1}</div>
    <div className={styles.Tip}>
      <h4>{tip.header}</h4>
      <p>{tip.content}</p>
    </div>
  </div>
);

const QuestionAndAnswer: FC<IQuestionProps> = ({ focusedFaq, ...faq }) => {
  const [answerVisibility, setAnswerVisibility] = useState(faq.id === focusedFaq);

  const focusedFaqRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    answerVisibility && focusedFaqRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [answerVisibility]);

  const toggleAnswer = () => {
    setAnswerVisibility(!answerVisibility);
  };

  const questionState = answerVisibility ? styles.Open : '';
  return (
    <div
      ref={answerVisibility ? focusedFaqRef : undefined}
      id={faq?.id}
      className={styles.QuestionBlock}
    >
      <div onClick={toggleAnswer} className={styles.Question}>
        <FontAwesomeIcon icon={faCaretRight} className={questionState} />
        <p className={questionState}>{faq.question}</p>
      </div>
      <AnimatePresence>
        {answerVisibility && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            className={styles.AnswerBlock}
          >
            {faq.body?.map((q, i) => (
              <div className={styles.Answer} key={i}>
                {/*{q.video && ( TODO: ADD VIDEO!! */}
                {/*  <VideoPlayer*/}
                {/*    videoId={q.video}*/}
                {/*    isTutorialVideo={true}*/}
                {/*    videoType={VIDEO_TYPE_TUTORIAL}*/}
                {/*    showSubtitles={true}*/}
                {/*  />*/}
                {/*)}*/}
                {q.heading && <p className={styles.AnswerHeading}>{q.heading}</p>}
                {q.answer && <ReactMarkdown linkTarget="_blank">{q.answer}</ReactMarkdown>}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQSection: FC<ISectionProps> = ({ focusedFaq, ...section }) => (
  <div className={styles.Faq}>
    <h3 className={styles.SectionTitle}>{section.title}</h3>
    {section.questions.map((faq, i) => (
      <QuestionAndAnswer focusedFaq={focusedFaq} {...faq} key={i} />
    ))}
  </div>
);

interface IFaqsProps {
  focusedFaq: string;
  faqs: FAQs;
}

export const FAQsContent: FC<IFaqsProps> = ({ faqs, focusedFaq }) => (
  <div className={styles.Container}>
    <div className={styles.FaqsInfo}>
      <div className={styles.FaqsIntro}>
        <div>
          <h2 className={styles.InfoTitle}>{faqs.infobox.title}</h2>
          <p className={styles.InfoSubtitle}>{faqs.infobox.subtitle}</p>
        </div>
        <img src={thumbIcon} alt="" />
      </div>
      <h3 className={styles.TipsSectionTitle}>{faqs.infobox.header}</h3>
      {faqs.infobox.tips.map((tip, i) => (
        <FAQInfoTip key={i} tip={tip} number={i} />
      ))}
    </div>
    <div>
      {faqs.sections.map((section, i) => (
        <FAQSection focusedFaq={focusedFaq} {...section} key={i} />
      ))}
    </div>
  </div>
);
