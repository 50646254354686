import './index.css';

import { App } from 'app/app';
import ReactDOM from 'react-dom/client';

declare global {
  interface Window {
    settings: {
      ENV_NAME?: 'dev' | 'test2' | 'prod';
      STUDENT_API_URL: string;
      READER_API_URL: string;
      READER_IMAGES_URL: string;
      SCIENCE_API_URL: string;
      PWWORKER_API_URL: string;
      INTERACTION_GATEWAY_API_URL: string;
    };
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
