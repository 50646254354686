import Tippy from '@tippyjs/react';
import { motion, useSpring } from 'motion/react';

type Props = {
  content: React.ReactNode;
  className: string;
  children: React.ReactElement<unknown>;
};

export const Tooltip = ({ content, className, children }: Props) => {
  const springConfig = { damping: 15, stiffness: 300 };
  const initialScale = 0.5;
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(initialScale, springConfig);

  const onMount = () => {
    scale.set(1);
    opacity.set(1);
  };
  const onHide = ({ unmount }: { unmount: () => void }) => {
    const cleanup = scale.onChange(value => {
      if (value <= initialScale) {
        cleanup();
        unmount();
      }
    });

    scale.set(initialScale);
    opacity.set(0);
  };

  return (
    <Tippy
      render={attrs => (
        <motion.div className={className} style={{ scale, opacity }} {...attrs}>
          {content}
        </motion.div>
      )}
      animation={true}
      onMount={onMount}
      onHide={onHide}
      interactive={true}
    >
      {children}
    </Tippy>
  );
};
